import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { ThemeContext } from '../context/ThemeContext'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Link from '../components/Link'
import Section from '../components/Section'
import { Grid, Column } from '../components/Grid'
import { base, H1, H4, Text } from '../components/Text'
import { formatPhone } from '../lib/format'
import { breakpoints, breakpointNr, fluidRange, colors } from '../style'
import getMetaFromPost from '../lib/getMetaFromPost'

const SmallText = styled(Text)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.4em;
  ${base}
  font-size: 0.75em;

  @media ${breakpoints.medium} {
    font-size: 0.8em;
  }

  @media ${breakpoints.large} {
    font-size: ${fluidRange({
      min: 16,
      max: 20,
      viewportMin: breakpointNr.large,
      viewportMax: breakpointNr.xlarge,
    })};
  }

  @media ${breakpoints.xlarge} {
    font-size: 1.25em;
  }

  a::after {
    bottom: -30%;
    background-size: 9px 11px;

    @media ${breakpoints.large} {
      bottom: -20%;
      background-size: 15px 11px;
    }
  }
`

const ImageWrapper = styled.div`
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-bottom: ${100 / 1}%;
`

const ColorOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: ${props => props.overlay};
`

const ColorCode = styled(Text)`
  position: absolute;
  left: 20px;
  bottom: 15px;
  z-index: 999;
  width: auto;
  margin: 0;
  display: block;
  color: ${colors.light};
  text-transform: uppercase;
`

export default function Contact({ data, location }) {
  const { page, contacts } = data.contentfulPage

  const theme = React.useContext(ThemeContext)

  return (
    <Layout meta={getMetaFromPost(page)} location={location}>
      <Hero>
        <H1
          textColor={theme.colorSecondary}
          css={css`
            font-weight: 900;
          `}
        >
          {page.title}
        </H1>

        {page.excerpt && (
          <Column md={8} lg={6} p={0}>
            <Text textColor={theme.color}>{page.excerpt.excerpt}</Text>
          </Column>
        )}
      </Hero>

      <Section mb={[4, 7]}>
        <Grid>
          {contacts.map((contact, index) => (
            <Column md={4} lg={index === 0 ? 6 : 3} key={contact.id}>
              <Text textColor={theme.color}>
                <H4 as="span" textColor={theme.colorSecondary}>
                  <strong>{contact.city || contact.title}</strong>
                </H4>
                <br />
                {contact.address}
                {contact.address && <br />}
                {contact.postalCode} {contact.city}
                {contact.city && <br />}
                {contact.email && (
                  <Link
                    href={`mailto:${contact.email}`}
                    textColor={theme.color}
                    styleVariant={theme.theme}
                  >
                    {contact.email}
                  </Link>
                )}
                <br />
                {contact.phone && (
                  <Link
                    href={`tel:${formatPhone(contact.phone)}`}
                    textColor={theme.color}
                    styleVariant={theme.theme}
                  >
                    {contact.phone}
                  </Link>
                )}
              </Text>
            </Column>
          ))}
        </Grid>
      </Section>
      <Section pb={[10, 20]}>
        <Grid>
          {data.employees.edges
            .sort((a, b) => {
              // Handling cases where "order" is not defined
              if (!a.order && !b.order) {
                return 0
              }
              if (!a.order) {
                return 1
              }
              if (!b.order) {
                return -1
              }
              return a.order - b.order
            })
            .filter(({ node }) => node.isHidden !== true)
            .map(({ node }) => (
              <Column key={node.id} sm={6} md={4} lg={3} bottomGap pt={2}>
                <ImageWrapper>
                  <ColorOverlay overlay={node.color}>
                    <ColorCode>{node.color}</ColorCode>
                  </ColorOverlay>
                </ImageWrapper>
                <Text textColor={theme.color} mt={[1, 1]} mb="0">
                  {node?.firstName} {node?.lastName}
                </Text>
                {node.role && (
                  <SmallText mb={0.5} textColor={theme.color}>
                    {node.role}
                  </SmallText>
                )}
                {node.email && (
                  <SmallText mb={0.5} textColor={theme.color}>
                    <Link
                      href={`mailto:${node.email}`}
                      textColor={theme.color}
                      styleVariant={theme.theme}
                    >
                      {node.email}
                    </Link>
                  </SmallText>
                )}
                {node.phone && (
                  <SmallText mb="0" textColor={theme.color}>
                    <Link
                      href={`tel:${formatPhone(node.phone)}`}
                      textColor={theme.color}
                      styleVariant={theme.theme}
                    >
                      {node.phone}
                    </Link>
                  </SmallText>
                )}
              </Column>
            ))}
        </Grid>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $locale: String!) {
    contentfulPage: contentfulContactPage(
      page: { slug: { eq: $slug }, node_locale: { eq: $locale } }
    ) {
      page {
        id
        title
        slug
        excerpt {
          excerpt
        }
        seoTitle
        seoDescription {
          seoDescription
        }
        seoImage {
          og: resize(width: 1200, height: 630, quality: 80) {
            src
          }
        }
      }
      contacts {
        id
        title
        email
        phone
        address
        city
        postalCode
      }
    }
    employees: allContentfulEmployees(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [firstName] }
    ) {
      edges {
        node {
          id
          firstName
          # lastName
          role
          email
          color
          order
          isHidden
        }
      }
    }
  }
`
